var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver"},[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('h3',[_vm._v("Información Personal")])]),_c('b-col',{attrs:{"lg":"7"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre *"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Apellido Paterno"}},[_c('validation-provider',{attrs:{"name":"Apellido Paterno","rules":"min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.paternal_surname),callback:function ($$v) {_vm.$set(_vm.formData, "paternal_surname", $$v)},expression:"formData.paternal_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Apellido Materno *"}},[_c('validation-provider',{attrs:{"name":"Apellido Materno","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.maternal_surname),callback:function ($$v) {_vm.$set(_vm.formData, "maternal_surname", $$v)},expression:"formData.maternal_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"sm":"9","lg":"8"}},[_c('b-form-group',{attrs:{"label":"CI *"}},[_c('validation-provider',{attrs:{"name":"CI","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"state":errors.length ? false : null},model:{value:(_vm.formData.ci),callback:function ($$v) {_vm.$set(_vm.formData, "ci", $$v)},expression:"formData.ci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"3","lg":"4"}},[_c('b-form-group',{attrs:{"label":"CI Exp."}},[_c('validation-provider',{attrs:{"name":"CI Exp. *","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.ciOptions,"clearable":false},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":errors.length ? false : null}},'input',attributes,false),events))]}}],null,true),model:{value:(_vm.formData.ci_exp),callback:function ($$v) {_vm.$set(_vm.formData, "ci_exp", $$v)},expression:"formData.ci_exp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha de Nacimiento *"}},[_c('validation-provider',{attrs:{"name":"Fecha de Nacimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"type":"date","max":_vm.today},model:{value:(_vm.formData.birth_date),callback:function ($$v) {_vm.$set(_vm.formData, "birth_date", $$v)},expression:"formData.birth_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Telefono/Celular *"}},[_c('validation-provider',{attrs:{"name":"Telefono/Celular","rules":"required|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length ? false : null,"type":"number"},model:{value:(_vm.formData.phone_number),callback:function ($$v) {_vm.$set(_vm.formData, "phone_number", $$v)},expression:"formData.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Género *"}},[_c('validation-provider',{attrs:{"name":"Género","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"mt-1",attrs:{"options":[
                  { text: 'Masculino', value: 'M' },
                  { text: 'Femenino', value: 'F' } ],"name":"radio-inline"},model:{value:(_vm.formData.gender),callback:function ($$v) {_vm.$set(_vm.formData, "gender", $$v)},expression:"formData.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }